<div class="mail-confirm-body flex-col flex-justify-center flex-align-center">
	<h2 class="mail-confirm-text">{{ 'dottnet.text.welcome' | translate }}</h2>
	<p class="lato-text">{{ mailConfirmationText }}</p>
	<div class="sign-label">
		<h5>- {{ 'dottnet.text.sign' | translate }}</h5>
	</div>
	<div class="flex-row flex-justify-center flex-align-center button-container">
		<button (click)="sendMail($event)" mat-raised-button color="accent" class="mail-confirm-button">
			{{ 'dottnet.confirmMail.button' | translate }}
		</button>
		<button
			*ngIf="isComingFromDrClick"
			(click)="returnToDrClick()"
			mat-raised-button
			color="accent"
			class="mail-confirm-button"
		>
			{{ 'dottnet.confirmMail.returnToDrClick' | translate }}
		</button>
	</div>
</div>
